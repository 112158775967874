import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L, { DivIcon } from "leaflet";
import { useAuth } from "../../auth/core/Auth";
import { getLocation, Location } from "../../services/GoogleMapsLocation";
import "./styles/index.scss";
import GoogleButton from "./GoogleButton";
import CodefixClient from "../../services/CodefixClient";
import ReactDOMServer from "react-dom/server";

import ClientDetailsDialog from "./components/DetailsClient";

import BoxDetailsDialog from "./components/DetailsBox";
import {
  CableColor,
  CableType,
  SplitterType,
  SupplyCableType,
} from "./enums/enums";
import ClosetDetailsDialog from "./components/DetailsCloset";
import FilterSection from "./FilterSection";

// Create a custom pulsating icon for current location
const createPulsatingIcon = (): DivIcon => {
  return L.divIcon({
    className: "pulsation-icon",
    html: `<div class="pulsating-circle"></div>`,
    iconSize: [50, 50],
  });
};

// Function to create a custom Leaflet icon with AssignmentIndIcon and dynamic font size
const createCustomIcon = (fontSize: number): DivIcon => {
  const iconHtml = ReactDOMServer.renderToString(
    <img
      src={require("./../../images/korisnik.webp")}
      alt="korisnik"
      style={{
        maxHeight: `${fontSize}px`,
        maxWidth: `${fontSize}px`,
        width: `${fontSize}px`,
        height: `${fontSize}px`,
      }}
    />
  );
  return L.divIcon({
    className: "custom-icon",
    html: iconHtml,
    iconSize: [fontSize, fontSize],
  });
};

const createBoxIcon = (fontSize: number): DivIcon => {
  const iconHtml = ReactDOMServer.renderToString(
    <img
      src={require("./../../images/kutija.webp")}
      alt="kutija"
      style={{
        maxHeight: `${fontSize}px`,
        maxWidth: `${fontSize}px`,
        width: `${fontSize}px`,
        height: `${fontSize}px`,
      }}
    />
  );
  return L.divIcon({
    className: "custom-icon",
    html: iconHtml,
    iconSize: [fontSize, fontSize],
  });
};

const createClosetIcon = (fontSize: number): DivIcon => {
  const iconHtml = ReactDOMServer.renderToString(
    <img
      src={require("./../../images/ormar.webp")}
      alt="ormar"
      style={{
        maxHeight: `${fontSize}px`,
        maxWidth: `${fontSize}px`,
        width: `${fontSize}px`,
        height: `${fontSize}px`,
      }}
    />
  );
  return L.divIcon({
    className: "custom-icon",
    html: iconHtml,
    iconSize: [fontSize, fontSize],
  });
};

export interface CurrentUserLocation {
  description: string;
  coordinate: [number, number];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TrackerDto {
  name: string;
  coordinate: [number, number];
}

export interface ClientDto {
  id: string;
  accountId: number;
  cableType: CableType;
  cableColor: CableColor;
  firstName?: string;
  lastName?: string;
  images: string[];
  coordinate: [number, number];
}

export interface BoxDto {
  id: string;
  name: string;
  description: string;
  splitters: SplitterType[];
  supplies: SupplyCableType[];
  images: string[];
  coordinate: [number, number];
}

export interface ClosetDto {
  id: string;
  name: string;
  description: string;
  splitters: SplitterType[];
  supplies: SupplyCableType[];
  images: string[];
  coordinate: [number, number];
}

const GoogleMaps = () => {
  const [currentLocation, setCurrentLocation] =
    useState<CurrentUserLocation | null>(null);
  const [clients, setClients] = useState<ClientDto[]>([]);
  const [boxes, setBoxes] = useState<BoxDto[]>([]);
  const [closets, setClosets] = useState<ClosetDto[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientDto | null>(null);
  const [selectedBox, setSelectedBox] = useState<BoxDto | null>(null);
  const [selectedCloset, setSelectedCloset] = useState<ClosetDto | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBoxDialogOpen, setIsBoxDialogOpen] = useState(false);
  const [isClosetDialogOpen, setIsClosetDialogOpen] = useState(false);
  const [iconSize, setIconSize] = useState<number>(20);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const client = new CodefixClient();

  const [showClients, setShowClients] = useState(true);
  const [showBoxes, setShowBoxes] = useState(true);
  const [showClosets, setShowClosets] = useState(true);

  // Adjust icon size based on screen size and map zoom level
  const adjustIconSize = (zoomLevel: number) => {
    const baseSize = 5; // Base size for icons
    const screenWidth = window.innerWidth;

    // Scale icons smaller on larger screens and bigger based on zoom
    const size = baseSize + zoomLevel * 2.5 - screenWidth * 0.01;

    setIconSize(size);
  };

  useEffect(() => {
    // Get current location and update the tracker list
    getLocation((location: Location) => {
      const userLocation: CurrentUserLocation = {
        description: user?.email as string,
        coordinate: [location.latitude, location.longitude],
      };
      setCurrentLocation(userLocation);
      setLoading(false);
    });

    (async () => {
      const response = await client.receivedClients();
      setClients(response);

      const responseBoxes = await client.receivedBoxes();
      setBoxes(responseBoxes);

      const responseClosets = await client.receivedClosets();
      setClosets(responseClosets);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultPosition: [number, number] = [42.133254, 21.720362]; // Fallback position

  const handleOpenDialog = (client: ClientDto) => {
    setSelectedClient(client);
    setIsDialogOpen(true);
    setIsBoxDialogOpen(false);
    setIsClosetDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedClient(null);
  };

  const handleOpenBoxDialog = (box: BoxDto) => {
    setSelectedBox(box);
    setIsBoxDialogOpen(true);
    setIsDialogOpen(false);
    setIsClosetDialogOpen(false);
  };

  const handleCloseBoxDialog = () => {
    setIsBoxDialogOpen(false);
    setSelectedBox(null);
  };

  const handleOpenClosetDialog = (closet: ClosetDto) => {
    setSelectedCloset(closet);
    setIsClosetDialogOpen(true);
    setIsBoxDialogOpen(false);
    setIsDialogOpen(false);
  };

  const handleCloseClosetDialog = () => {
    setIsClosetDialogOpen(false);
    setSelectedCloset(null);
  };

  const handleSaveClientChanges = async (updatedClient: any) => {
    try {
      await client.requestUpdateClient(
        selectedClient?.id.toString() as string,
        updatedClient
      );

      const response = await client.receivedClients();
      setClients(response);

      const responseBoxes = await client.receivedBoxes();
      setBoxes(responseBoxes);

      const responseClosets = await client.receivedClosets();
      setClosets(responseClosets);

      handleCloseDialog();
    } catch (error) {
      console.error("Failed to update client:", error);
    }
  };

  const handleSaveBoxChanges = async (updatedBox: any) => {
    try {
      await client.requestUpdateBox(
        selectedBox?.id.toString() as string,
        updatedBox
      );

      const responseBoxes = await client.receivedBoxes();
      setBoxes(responseBoxes);

      const responseClients = await client.receivedClients();
      setClients(responseClients);

      const responseClosets = await client.receivedClosets();
      setClosets(responseClosets);

      handleCloseBoxDialog();
    } catch (error) {
      console.error("Failed to update box:", error);
    }
  };

  const handleSaveClosetChanges = async (updatedCloset: any) => {
    try {
      await client.requestUpdateCloset(
        selectedCloset?.id.toString() as string,
        updatedCloset
      );

      const responseClosets = await client.receivedClosets();
      setClosets(responseClosets);

      const responseClients = await client.receivedClients();
      setClients(responseClients);

      const responseBoxes = await client.receivedBoxes();
      setBoxes(responseBoxes);

      handleCloseClosetDialog();
    } catch (error) {
      console.log("Failed to update closet:", error);
    }
  };

  // Use MapEvents to listen to zoom level changes
  const MapZoomListener = () => {
    useMapEvents({
      zoomend: (e) => {
        adjustIconSize(e.target.getZoom()); // Adjust icon size based on zoom level
      },
    });

    return null;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      style={{ width: "100%", height: "100%" }}
    >
      <FilterSection
        showClients={showClients}
        setShowClients={setShowClients}
        showBoxes={showBoxes}
        setShowBoxes={setShowBoxes}
        showClosets={showClosets}
        setShowClosets={setShowClosets}
      />
      <MapContainer
        center={currentLocation?.coordinate as [number, number]}
        zoom={14}
        maxZoom={22}
        style={{ height: "100vh" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {/* Listen to zoom events */}
        <MapZoomListener />

        {currentLocation && (
          <Marker
            position={currentLocation.coordinate}
            icon={createPulsatingIcon()}
          ></Marker>
        )}

        {showClients &&
          clients &&
          clients.length > 0 &&
          clients.map((client, i) => (
            <Marker
              key={i}
              position={client.coordinate}
              icon={createCustomIcon(iconSize)}
              // icon={trackerIcon}
            >
              <Popup>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {client.accountId}
                  </Typography>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Кабел: {client.cableType}
                      {/* Име: {client.firstName} */}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Боја: {client.cableColor}
                      {/* Презиме: {client.lastName} */}
                    </Typography>
                  </Box>

                  <Button
                    onClick={() => handleOpenDialog(client)}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: 1,
                      paddingX: 2,
                      borderRadius: "20px",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      backgroundColor: "#292828",
                      "&:hover": {
                        backgroundColor: "#0d0b0b",
                      },
                    }}
                  >
                    Details
                  </Button>
                </Box>
              </Popup>
            </Marker>
          ))}

        {showBoxes &&
          boxes &&
          boxes.length > 0 &&
          boxes.map((box, i) => (
            <Marker
              key={i}
              position={box.coordinate}
              icon={createBoxIcon(iconSize)}
            >
              <Popup>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Кутија
                  </Typography>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Име: {box.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Опис: {box.description}
                    </Typography>
                  </Box>

                  <Button
                    onClick={() => handleOpenBoxDialog(box)}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: 1,
                      paddingX: 2,
                      borderRadius: "20px",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      backgroundColor: "#292828",
                      "&:hover": {
                        backgroundColor: "#0d0b0b",
                      },
                    }}
                  >
                    Details
                  </Button>
                </Box>
              </Popup>
            </Marker>
          ))}

        {showClosets &&
          closets &&
          closets.length > 0 &&
          closets.map((closet, i) => (
            <Marker
              key={i}
              position={closet.coordinate}
              icon={createClosetIcon(iconSize)}
            >
              <Popup>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Ормар
                  </Typography>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Име: {closet.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
                    >
                      Опис: {closet.description}
                    </Typography>
                  </Box>

                  <Button
                    onClick={() => handleOpenClosetDialog(closet)}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: 1,
                      paddingX: 2,
                      borderRadius: "20px",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      backgroundColor: "#292828",
                      "&:hover": {
                        backgroundColor: "#0d0b0b",
                      },
                    }}
                  >
                    Details
                  </Button>
                </Box>
              </Popup>
            </Marker>
          ))}

        <GoogleButton
          coordinate={currentLocation?.coordinate as [number, number]}
        />
      </MapContainer>

      {selectedClient && (
        <ClientDetailsDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          selectedClient={selectedClient}
          onSaveChanges={handleSaveClientChanges}
        />
      )}

      {selectedBox && (
        <BoxDetailsDialog
          open={isBoxDialogOpen}
          onClose={handleCloseBoxDialog}
          selectedBox={selectedBox}
          onSaveChanges={handleSaveBoxChanges}
        />
      )}

      {selectedCloset && (
        <ClosetDetailsDialog
          open={isClosetDialogOpen}
          onClose={handleCloseClosetDialog}
          selectedCloset={selectedCloset}
          onSaveChanges={handleSaveClosetChanges}
        />
      )}
    </Box>
  );
};

export default GoogleMaps;
