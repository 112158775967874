import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
  TextField,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../auth/core/Auth";
import { UserRole } from "../../../interfaces/types";
import { ClosetDto } from "../GoogleMaps";

import webConfig from "../../../config/config";
import AddIcon from "@mui/icons-material/Add";
import { SplitterType, SupplyCableType } from "../enums/enums";

interface ClosetDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  selectedCloset: ClosetDto | null;
  onSaveChanges: (data: any) => void; // This will be called when the save changes button is clicked
}

const ClosetDetailsDialog: React.FC<ClosetDetailsDialogProps> = ({
  open,
  onClose,
  selectedCloset,
  onSaveChanges,
}) => {
  const [name, setName] = useState<string>(selectedCloset?.name ?? "");
  const [description, setDescription] = useState<string>(
    selectedCloset?.description ?? ""
  );
  const [images, setImages] = useState<string[]>(selectedCloset?.images ?? []);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [removedImages, setRemovedImages] = useState<string[]>([]);
  const [splitters, setSplitters] = useState<SplitterType[]>(
    selectedCloset?.splitters ?? []
  );
  const [supplies, setSupplies] = useState<SupplyCableType[]>(
    selectedCloset?.supplies ?? []
  );
  const [newSplitter, setNewSplitter] = useState<SplitterType | undefined>();
  const [newSupply, setNewSupply] = useState<SupplyCableType | undefined>();

  const { user } = useAuth();

  const handleSaveChanges = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    removedImages.forEach((image) => {
      formData.append("removedImages", image);
    });

    newImages.forEach((image) => {
      formData.append("newImages", image);
    });

    splitters.forEach((splitter) => {
      formData.append("splitters", splitter);
    });

    supplies.forEach((supply) => {
      formData.append("supplies", supply);
    });

    onSaveChanges(formData);
    onClose();
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setNewImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const handleRemoveImage = (image: string) => {
    setImages((prevImages) => prevImages.filter((img) => img !== image));
    setRemovedImages((prevRemoved) => [...prevRemoved, image]);
  };

  const handleRemoveNewImage = (index: number) => {
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveSplitter = (index: number) => {
    setSplitters((prevSplitters) =>
      prevSplitters.filter((_, i) => i !== index)
    );
  };

  const handleRemoveSupply = (index: number) => {
    setSupplies((prevSupplies) => prevSupplies.filter((_, i) => i !== index));
  };

  const handleAddSplitter = () => {
    if (newSplitter) {
      setSplitters((prevSplitters) => [...prevSplitters, newSplitter]);
      setNewSplitter(undefined); // Reset the selection
    }
  };

  const handleAddSupply = () => {
    if (newSupply) {
      setSupplies((prevSupplies) => [...prevSupplies, newSupply]);
      setNewSupply(undefined); // Reset the selection
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 2,
        }}
      >
        <Typography variant="h6">Ормар - {selectedCloset?.name}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Form to edit details */}
        <Box mt={2}>
          <TextField
            fullWidth
            label="Име"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            variant="outlined"
            margin="dense"
            disabled={user!.role !== UserRole.Admin}
          />
          <TextField
            fullWidth
            label="Опис"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            variant="outlined"
            margin="dense"
            disabled={user!.role !== UserRole.Admin}
          />

          {/* Splitters */}
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            alignItems="center"
            mt={2}
            pb={1}
          >
            <Grid item xs={6} sm={5} md={6} lg={6}>
              <Typography variant="body1" mt={2}>
                Сплитери
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={4} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Нов сплитер</InputLabel>
                <Select
                  size="small"
                  value={newSplitter || ""}
                  onChange={(e) =>
                    setNewSplitter(e.target.value as SplitterType)
                  }
                >
                  {Object.values(SplitterType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#292828",
                  "&:hover": {
                    backgroundColor: "#0d0b0b",
                  },
                }}
                onClick={handleAddSplitter}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>

          <TableContainer>
            <Table size="small">
              <TableBody>
                {splitters.map((splitter, index) => (
                  <TableRow key={index}>
                    <TableCell>{splitter}</TableCell>
                    {user!.role === UserRole.Admin && (
                      <TableCell align="right">
                        <IconButton
                          aria-label="remove splitter"
                          size="small"
                          onClick={() => handleRemoveSplitter(index)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            alignItems="center"
            mt={2}
            pb={1}
          >
            <Grid item xs={6} sm={5} md={6} lg={6}>
              <Typography variant="body1" mt={2}>
                Доводен кабел
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={4} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel>Нов Довод</InputLabel>
                <Select
                  size="small"
                  value={newSupply || ""}
                  onChange={(e) =>
                    setNewSupply(e.target.value as SupplyCableType)
                  }
                >
                  {Object.values(SupplyCableType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#292828",
                  "&:hover": {
                    backgroundColor: "#0d0b0b",
                  },
                }}
                onClick={handleAddSupply}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>

          <TableContainer>
            <Table size="small">
              <TableBody>
                {supplies.map((supply, index) => (
                  <TableRow key={index}>
                    <TableCell>{supply}</TableCell>
                    {user!.role === UserRole.Admin && (
                      <TableCell align="right">
                        <IconButton
                          aria-label="remove supply"
                          size="small"
                          onClick={() => handleRemoveSupply(index)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={6}>
            <input
              accept="image/*"
              id="upload-image"
              type="file"
              multiple
              onChange={handleImageUpload}
            />
          </Box>

          {/* Show new images to be uploaded */}
          {newImages.length > 0 && (
            <Grid container spacing={2} mt={2}>
              {newImages.map((image, index) => (
                <Grid item key={index} xs={6} sx={{ position: "relative" }}>
                  <Typography variant="body2">{image.name}</Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveNewImage(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          )}

          {/* Show list of existing images */}
          {images && images.length > 0 ? (
            <Grid container spacing={2} mt={2}>
              {images.map((image, index) => (
                <Grid item key={index} xs={6} sx={{ position: "relative" }}>
                  {/* eslint-disable-next-line */}
                  <img
                    src={`${webConfig.apiBaseUrl}/public/closet/${image}`}
                    alt={`box image ${index}`}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `${webConfig.apiBaseUrl}/public/closet/${image}`
                      )
                    }
                  />
                  {user!.role === UserRole.Admin && (
                    <IconButton
                      aria-label="remove image"
                      size="small"
                      onClick={() => handleRemoveImage(image)}
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: -8,
                        backgroundColor: "rgba(245, 22, 22, 0.8)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(245, 22, 22, 1)",
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" mt={2}>
              No images available.
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid
          container
          spacing={2}
          p={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Button onClick={() => onClose()} sx={{ mt: 0, color: "#242323" }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#292828",
                "&:hover": {
                  backgroundColor: "#0d0b0b",
                },
              }}
              onClick={handleSaveChanges}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ClosetDetailsDialog;
